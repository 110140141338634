"use client";
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import { useState, MouseEvent } from "react";

interface ProfileProps {
  name: string;
  designation: string;
  imageSrc: string;
}

export const AnimatedProfile = ({
  name,
  designation,
  imageSrc,
}: ProfileProps) => {
  const [isHovered, setHovered] = useState<boolean | false>(false);

  const springConfig = { stiffness: 100, damping: 5 };

  const x = useMotionValue(0); // going to set this value on mouse move

  // rotate the tooltip
  const rotate = useSpring(
    useTransform(x, [-100, 100], [-45, 45]),
    springConfig
  );

  // translate the tooltip
  const translateX = useSpring(
    useTransform(x, [-100, 100], [-50, 50]),
    springConfig
  );

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    const halfWidth = event.currentTarget.offsetWidth / 2;
    x.set(event.nativeEvent.offsetX - halfWidth); // set the x value, which is then used in transform and rotate
  };

  return (
    <div className="flex flex-row items-center justify-center w-full">
      <div
        className="relative group"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <AnimatePresence mode="wait">
          {isHovered && (
            <motion.div
              initial={{ opacity: 0, y: 20, scale: 1.6 }}
              animate={{
                opacity: 1,
                y: 0,
                scale: 1,
                transition: {
                  type: "spring",
                  stiffness: 260,
                  damping: 10,
                },
              }}
              exit={{ opacity: 0, y: 20, scale: 0.6 }}
              style={{
                translateX: translateX,
                rotate: rotate,
                whiteSpace: "nowrap",
              }}
              className="absolute -top-6 -left-1/2 translate-x-1/2 flex text-xs flex-col items-center justify-center rounded-md bg-black/60 z-50 shadow-xl px-4 py-2"
            >
              <div className="absolute inset-x-10 z-30 w-[20%] -bottom-px bg-gradient-to-r from-transparent via-emerald-500 to-transparent h-px " />
              <div className="absolute left-10 w-[40%] z-30 -bottom-px bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px " />
              <div className="font-bold text-white relative z-30 text-base">
                {name}
              </div>
              <div className="text-white text-xs">{designation}</div>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="w-24 mask mask-squircle">
          <img
            onMouseMove={handleMouseMove}
            height={120}
            width={120}
            src={imageSrc}
            alt={name}
          />
        </div>
      </div>
    </div>
  );
};
export default AnimatedProfile;
