import { motion } from "framer-motion";
import { NavLink, useLocation } from "react-router-dom";
import { staggerListItem } from "@/utils/motion";
import { StaggeredList } from "@/components/StaggeredList";
import ROUTES from "@/routes";

function Menu() {
  const location = useLocation();
  const { pathname } = location;

  const menu = ROUTES.map((item, index) => (
    <motion.li
      key={`menu-${index}`}
      variants={staggerListItem()}
      // initial="hidden"
      // animate="visible"
      // whileHover={{
      //   scale: 1.1,
      //   fontWeight: "600",
      // }}
      whileTap={{ scale: 0.9 }}
      className={`relative w-full text-slate-300 text-left px-4 py-2 hover:text-slate-100`}
      style={{
        WebkitTapHighlightColor: "transparent",
      }}
    >
      <NavLink
        to={item.path}
        // className="relative link-effect hover:text-white/60 bg-white flex justify-between gap-x-6 py-5"
        className={`block link-effect w-full  ${
          item.path === pathname ? "font-extrabold" : ""
        }`}
        // style={{ borderRadius: 9999 }}
      >
        {/* // maybe later..  */}
        {/* <a className="tooltip tooltip-right" data-tip="Home">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
        </a> */}
        {item.path === pathname && (
          <motion.span
            layoutId="bubble"
            className="absolute inset-0 py-1 bg-white/20"
            transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
          />
        )}
        {item.name}
      </NavLink>
    </motion.li>

    // </StaggeredListItem>
  ));

  return <StaggeredList className="">{menu}</StaggeredList>;
}

export default Menu;
