import React, { useRef, useState } from "react";
import { motion } from "framer-motion";

interface SpotlightCardProps {
  children: React.ReactNode;
}

const SpotlightCard = ({ children }: SpotlightCardProps) => {
  const divRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(0);

  const handleMouseMove = (e: { clientX: number; clientY: number }) => {
    if (!divRef.current || isFocused) return;

    const div: HTMLDivElement = divRef.current;
    const rect = div.getBoundingClientRect();

    setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleFocus = () => {
    setIsFocused(true);
    setOpacity(1);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setOpacity(0);
  };

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  return (
    <motion.div
      variants={{
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1,
        },
      }}
    >
      <div
        ref={divRef}
        onMouseMove={handleMouseMove}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="relative bg-base-300/70 text-right w-full block overflow-hidden shadow-[0_1px_5px_rgb(0_0_0/15%)] transition-all duration-[0.2s] ease-[ease-in-out] mt-0 mb-[15px] mx-0 p-5 rounded-[10px] hover:-translate-y-2  hover:shadow-lg hover:shadow-amber-500/50"
      >
        <div
          className="pointer-events-none absolute opacity-0 transition-opacity duration-300 -inset-px"
          style={{
            opacity,
            background: `radial-gradient(600px circle at ${position.x}px ${position.y}px, rgba(255,255,255,.06), transparent 40%)`,
            // background: `radial-gradient(350px circle at ${position.x}px ${position.y}px, rgba(14, 165, 233, 0.15), transparent 80%)`,
          }}
        />
        {children}
      </div>
    </motion.div>
  );
};

export default SpotlightCard;
