import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import IconButton from "@/components/icons/IconButton";
import { staggerListItem } from "@/utils/motion";

function Footer() {
  const { t } = useTranslation();

  return (
    <motion.footer
      className="footer footer-center absolute bottom-0 left-0"
      variants={{
        hidden: { opacity: 0, scale: 0.5 },
        visible: { opacity: 1, scale: 1 },
      }}
      transition={{
        duration: 0.8,
        delay: 0.6,
        delayChildren: 0.3,
        staggerChildren: 0.2,
        ease: "easeInOut",
        // ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <section className="max-w-screen-xl px-4 py-4 mx-auto space-y-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="flex justify-center  space-x-4">
          {t("social-links", { returnObjects: true }).map((item, index) => (
            <motion.div
              key={`social-links-${index}`}
              variants={{
                hidden: { opacity: 0, scale: 0.5 },
                visible: { opacity: 1, scale: 1 },
              }}
            >
              <IconButton href={item.href} name={item.name} />
            </motion.div>
          ))}
        </div>
        <p className="text-sm text-slate-400">{t("copyrights")}</p>
        {/* <p className="mt-8 text-base leading-6 text-center text-gray-400">
          <ProfileLinks />
        </p> */}
      </section>
    </motion.footer>
  );
}

export default Footer;
