import { motion } from "framer-motion";
import { createElement } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons/lib";
import PageTransition from "../components/PageTransition";
import SpotlightCard from "../components/SpotlightCard";
import FullName from "./FullName";

const AboutMe = () => {
  const { t } = useTranslation();

  const overview = t("overview", { returnObjects: true });
  const services = t("overview-services", { returnObjects: true });

  return (
    <PageTransition noPadding>
      <div
        className="bg-fixed bg-right-bottom bg-no-repeat backdrop-blur-md backdrop-opacity-60"
        style={{ backgroundImage: "url(assets/home.svg)" }}
      >
        <div className="px-12 py-8 bg-[#191e24]/90 space-y-6">
          <p className="text-white font-black lg:text-[50px] sm:text-[40px] xs:text-[30px] text-[20px] lg:leading-[60px]">
            <span className="text-[#dfd9ff] font-medium lg:text-[30px] sm:text-[26px] xs:text-[20px] text-[16px] lg:leading-[40px]">
              {t("hello")}
            </span>{" "}
            <FullName />
          </p>
          <motion.ul
            role="list"
            variants={{
              hidden: { opacity: 0 },
              visible: {
                opacity: 1,
              },
            }}
            className="divide-y divide-gray-700"
          >
            {overview.map((item, index) => (
              <li
                key={`overview-list-item=${index}`}
                className="flex justify-between gap-x-6 py-5"
              >
                <span className="text-justify first-line:tracking-widest first-letter:text-4xl first-letter:font-bold first-letter:text-amber-100 hover:first-letter:text-amber-300 text-gray-300 hover:text-white first-letter:mr-3 first-letter:float-left">
                  {item}
                </span>
              </li>
            ))}
          </motion.ul>
          {services.length > 0 && (
            <div
              className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ${
                services.length > 3 && "xl:grid-cols-4"
              } ${services.length > 5 && "2xl:grid-cols-6"} gap-3`}
            >
              {services.map((item, index) => (
                <SpotlightCard key={`services-${index}`}>
                  <IconContext.Provider
                    value={{
                      className: "hidden",
                      color: "blue",
                      size: "2em",
                    }}
                  >
                    {createElement(item.icon)}
                  </IconContext.Provider>
                  <h4 className="text-base">{item.title}</h4>
                  {/* <span className="text-4xl block leading-snug">4,780</span> */}
                  <span>{item.subtitle}</span>
                </SpotlightCard>
              ))}
            </div>
          )}
        </div>
      </div>
    </PageTransition>
  );
};

export default AboutMe;
