import { SimpleGrid } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { FaGithub, FaReact } from "react-icons/fa6";
import PageTransition from "../components/PageTransition";
import SpotlightCard from "../components/SpotlightCard";
import "../translations/i18n";

function Portfolio() {
  const { t } = useTranslation();
  return (
    <PageTransition noPadding>
      <div
        className="bg-fixed bg-right-bottom bg-no-repeat backdrop-blur-md backdrop-opacity-60"
        style={{ backgroundImage: "url(assets/home.svg)" }}
      >
        <div className="px-12 py-8 bg-[#191e24]/90 space-y-6">
          <p className="text-white font-black lg:text-[50px] sm:text-[40px] xs:text-[30px] text-[20px] lg:leading-[60px]">
            <span className="text-[#dfd9ff] font-medium lg:text-[30px] sm:text-[26px] xs:text-[20px] text-[16px] lg:leading-[40px]">
              {t("hello")}
            </span>{" "}
            <span className="">{t("firstName")}</span>{" "}
            <span className="text-amber-400">{t("lastName")}</span>
          </p>

          <ul role="list" className="divide-y divide-gray-700">
            {t("portfolio", { returnObjects: true }).map((item, index) => (
              <li
                key={`portfolio-list-item=${index}`}
                className="flex justify-between gap-x-6 py-5"
              >
                <span className="text-justify first-line:tracking-widest first-letter:text-4xl first-letter:font-bold first-letter:text-amber-100 hover:first-letter:text-amber-300 text-gray-300 hover:text-white first-letter:mr-3 first-letter:float-left">
                  {item}
                </span>
              </li>
            ))}
          </ul>

          <SimpleGrid
            cols={3}
            spacing="lg"
            breakpoints={[
              // { maxWidth: 'md', cols: 3, spacing: 'md' },
              { maxWidth: "xs", cols: 1, spacing: "sm" },
            ]}
          >
            <SpotlightCard>
              <FaReact />
              {/* <i className="lnr lnr-heart"></i> */}
              <h4 className="text-base">Frontend Development Excellence</h4>
              {/* <span className="spotlight-card-block-value">10+</span> */}
              <span className="spotlight-card-block-text">
                I use cutting-edge technologies like React, NextJS, and Redux.{" "}
              </span>
            </SpotlightCard>

            <SpotlightCard>
              <FaGithub />
              {/* <i className="lnr lnr-clock"></i> */}
              <h4 className="text-base">
                Performance Optimization and Debugging
              </h4>
              {/* <span className="spotlight-card-block-value">4,780</span> */}
              <span className="spotlight-card-block-text">
                I ensure your applications run smoothly
              </span>
            </SpotlightCard>

            <SpotlightCard>
              <FaGithub />
              {/* <i className="lnr lnr-star"></i> */}
              <h4 className="text-base">Full-Cycle Project Leadership</h4>
              {/* <span className="spotlight-card-block-value">15</span> */}
              <span className="spotlight-card-block-text">
                {" "}
                I offer comprehensive project leadership services
              </span>
            </SpotlightCard>
          </SimpleGrid>
        </div>
      </div>
    </PageTransition>
  );
}

export default Portfolio;
