import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation } from "react-router-dom";
import { SuperSEO } from "react-super-seo";
import FancyCursor from "./components/FancyCursor";
import InitialTransition from "./components/InitTransition";
import Footer from "./components/layout/Footer";
import PageLayout from "./components/layout/Layout";
import Navbar from "./components/layout/Navbar";
import ROUTES from "./routes";

const App = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const container = {
    hidden: {
      //  opacity: 0, scale: 0.5
    },
    visible: {
      // scale: 1,
      // opacity: 1,
      transition: {
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowElement(true);
    }, 2400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <SuperSEO
        title={t("site.title")}
        description={t("site.description")}
        lang={i18n.language}
        charset="utf-8"
      />

      <InitialTransition
        onComplete={() => {
          // setLoaded(true);
        }}
      />

      <FancyCursor />

      <div
        className="bg-cover bg-[center_center] box-border absolute w-auto h-auto bg-no-repeat z-[-1] opacity-70 -inset-7"
        style={{ backgroundImage: "url(assets/bg.jpg)" }}
      />

      <motion.div
        variants={container}
        animate={showElement ? "visible" : "hidden"}
        className="min-h-screen m-auto -space-y-4 items-center justify-center md:flex md:space-y-0 xl:w-10/12 "
      >
        <div className="relative z-10 -mx-4 group w-[240px]">
          <Navbar />
          <button className="btn btn-square btn-ghost">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-5 h-5 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
        <AnimatePresence mode="wait">
          <Routes key={location.pathname} location={location}>
            <Route element={<PageLayout loaded={showElement} />}>
              {ROUTES.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
            </Route>
          </Routes>
        </AnimatePresence>
        <Footer />
      </motion.div>
    </>
  );
};

export default App;
