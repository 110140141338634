import AnimatedProfile from "@/components/AnimatedProfile";
import Menu from "@/components/layout/Menu";
import { PROFILE_URL } from "@/config";
import FullName from "@/pages/FullName";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const simpleReveal = {
  hidden: { opacity: 0 },
  visible: {
    transition: {
      delay: 0.2,
    },
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

function Navbar() {
  const { t } = useTranslation();

  return (
    <motion.aside
      variants={{
        hidden: { opacity: 0, scale: 0.5 },
        visible: { opacity: 1, scale: 1 },
      }}
      transition={{
        duration: 0.8,
        delay: 0.4,
        delayChildren: 0.3,
        staggerChildren: 0.2,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <div className="w-[240px] shadow-xl ring ring-white/20 backdrop-blur-sm h-full text-center space-y-4 rounded-2xl hidden lg:block bg-base-100/60 py-4">
        <AnimatedProfile
          imageSrc={PROFILE_URL}
          designation={t("designation")}
          name={`${t("firstName")} ${t("lastName")}`}
        />
        <motion.div variants={simpleReveal}>
          <h1 className="">
            <FullName />
          </h1>
        </motion.div>

        <Menu />
      </div>
    </motion.aside>
  );
}

export default Navbar;
