import { Grid } from "@mantine/core";
import { useTranslation } from "react-i18next";
import PageTransition from "../components/PageTransition";

function Skills() {
  const { t } = useTranslation("resume");
  return (
    <PageTransition>
      {/* <Title>{t("Skills")}</Title> */}
      <Grid
        cols={2}
        spacing="lg"
        gutter={"xs"}
        grow
        // breakpoints={[
        //   // { maxWidth: 'md', cols: 3, spacing: 'md' },
        //   { maxWidth: "xs", cols: 1, spacing: "sm" },
        // ]}
      >
        <Grid.Col span={6}>
          <div className="skills-info skills-second-style">
            <div className="skill clearfix">
              <h4>HTML, CSS, JS</h4>
              <div className="skill-value">95%</div>
            </div>
            <div className="skill-container skill-1">
              <div className="skill-percentage"></div>
            </div>
            <div className="skill clearfix">
              <h4>ReactJS</h4>
              <div className="skill-value">95%</div>
            </div>
            <div className="skill-container skill-1">
              <div className="skill-percentage"></div>
            </div>
            <div className="skill clearfix">
              <h4>Java Core / J2EE</h4>
              <div className="skill-value">95%</div>
            </div>
            <div className="skill-container skill-1">
              <div className="skill-percentage" style={{ width: "80%" }}></div>
            </div>
            <div className="skill clearfix">
              <h4>NodeJS</h4>
              <div className="skill-value">95%</div>
            </div>
            <div className="skill-container skill-1">
              <div className="skill-percentage" style={{ width: "62%" }}></div>
            </div>
            <div className="skill clearfix">
              <h4>ExpressJS</h4>
              <div className="skill-value">95%</div>
            </div>
            <div className="skill-container skill-1">
              <div className="skill-percentage" style={{ width: "45%" }}></div>
            </div>
            <div className="skill clearfix">
              <h4>React Native</h4>
              <div className="skill-value">95%</div>
            </div>
            <div className="skill-container skill-1">
              <div className="skill-percentage" style={{ width: "40%" }}></div>
            </div>
            <div className="skill clearfix">
              <h4>Flutter</h4>
              <div className="skill-value">95%</div>
            </div>
            <div className="skill-container skill-1">
              <div className="skill-percentage" style={{ width: "70%" }}></div>
            </div>
          </div>
        </Grid.Col>
        <Grid.Col span={6}>
          {/* <div className="col-xs-12 col-sm-5"> */}

          {/* <div className="skill clearfix">
          <h4>Web Design</h4>
          <div className="skill-value">95%</div>
        </div>
        <div className="skill-container skill-1">
          <div className="skill-percentage"></div>
        </div>
      
        <div className="skill clearfix">
          <h4>Print Design</h4>
          <div className="skill-value">65%</div>
        </div>
        <div className="skill-container skill-2">
          <div className="skill-percentage"></div>
        </div>

        <div className="skill clearfix">
          <h4>Logo Design</h4>
          <div className="skill-value">80%</div>
        </div>
        <div className="skill-container skill-3">
          <div className="skill-percentage"></div>
        </div>

        <div className="skill clearfix">
          <h4>Graphic Design</h4>
          <div className="skill-value">90%</div>
        </div>
        <div className="skill-container skill-4">
          <div className="skill-percentage"></div>
        </div>

        */}
          {/* <div className="white-space-10"></div>


        <div className="white-space-10"></div> */}
          {/* 
        <!-- Knowledges -->
                  */}

          <div className="block-title">
            <h3>Technical Skills</h3>
          </div>

          {t("skills", { ns: "resume", returnObjects: true }).map(
            (skill, idx) => (
              <span
                key={`topskills${idx}`}
                className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 m-1"
              >
                {skill}
              </span>
            )
          )}
        </Grid.Col>
      </Grid>
    </PageTransition>
  );
}
export default Skills;
