import { motion } from "framer-motion";

import { useRef } from "react";
import { Outlet } from "react-router-dom";
import "../../translations/i18n";
import AnimatedRouterTitle from "../AnimatedRouterTitle";
type PageLayoutProps = {
  loaded: boolean;
};

const animation = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      delayChildren: 1,
      staggerChildren: 0.2,
      duration: 1.7,
      type: "spring",
      stiffness: 260,
      damping: 20,
      ease: "easeOut",
    },
  },
  // exit: { y: 20, opacity: 0 },
};
function PageLayout({ loaded }: PageLayoutProps) {
  const outletWrapperRef = useRef<HTMLDivElement>(null);
  return (
    <motion.div
      layoutId="page"
      className="relative  md:max-w-[930px]  shadow-2xl px-0 max-lg:px-4"
      // className="z-10 flex flex-col flex-grow mt-8 overflow-hidden transform bg-white rounded-lg shadow-lg "
      variants={animation}
      onAnimationStart={() => {
        outletWrapperRef.current?.classList.add("overflow-y-hidden");
      }}
      onAnimationComplete={() => {
        outletWrapperRef.current?.classList.remove("overflow-y-hidden");
      }}
    >
      <div className="relative mockup-browser bg-base-300 h-full">
        <div className="mockup-browser-toolbar">
          <div className="input pt-1 text-sm">
            <AnimatedRouterTitle />
          </div>
        </div>
        <div
          ref={outletWrapperRef}
          className="max-h-[690px] bg-base-200 overflow-x-hidden"
        >
          <Outlet />
        </div>
      </div>
    </motion.div>
  );
}

export default PageLayout;
