import { useTranslation } from "react-i18next";
import "../translations/i18n";

const FullName = () => {
  const { t } = useTranslation();
  return (
    <>
      <span className="">{t("firstName")}</span>{" "}
      <span className="text-amber-400">{t("lastName")}</span>
    </>
  );
};

export default FullName;
