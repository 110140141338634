import { motion } from "framer-motion";
import { staggerList, staggerListItem } from "../utils/motion";

type Props = {
  key?: string;
  children: JSX.Element | JSX.Element[] | string | string[];
};

const StaggeredListItem = ({ children, key }: Props) => {
  return (
    <motion.li key={key} variants={staggerListItem()}>
      {children}
    </motion.li>
  );
};

type StaggeredListProps = {
  children: JSX.Element | JSX.Element[] | string | string[];
  className?: string;
};

const StaggeredList = ({ children, className }: StaggeredListProps) => {
  return (
    <motion.ul
      className={className}
      variants={staggerList()}
      initial="hidden"
      animate="visible"
    >
      {children}
    </motion.ul>
  );
};

export { StaggeredList, StaggeredListItem };
