import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import TypewriterComponent from "typewriter-effect";
import { useAnimate, usePresence } from "framer-motion";
import { useEffect } from "react";
import Routes from "../routes";
import Title from "./Title";

const animation = {
  hidden: { y: -20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      // duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
  exit: { y: -20, opacity: 0 },
};

const getMenuTitleByPath = (path: string) => {
  const { t } = useTranslation();
  const menuItem = Routes.find((item) => item.path === path);
  return menuItem ? t(menuItem.name) : "Path not found";
};

// It's possible to compose your own exit animations when a component
//  is removed using useAnimate in conjunction with usePresence.
const AnimatedRouterTitle = () => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  const { pathname } = useLocation();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(scope.current, { opacity: 1 });
        // await animate("li", { opacity: 1, x: 0 });
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        // await animate("li", { opacity: 0, x: -100 });
        await animate(scope.current, { opacity: 0 });
        safeToRemove();
      };

      exitAnimation();
    }
  }, [isPresent]);

  return (
    <div ref={scope}>
      <Title>
        <TypewriterComponent
          options={{
            strings: getMenuTitleByPath(pathname),
            autoStart: true,
            cursor: "",
            // loop: true,
          }}
        />
      </Title>
    </div>
  );
};

export default AnimatedRouterTitle;
