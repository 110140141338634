import { useTranslation } from "react-i18next";
import PageTransition from "../components/PageTransition";

function ExperienceItem(item, index) {
  return (
    <div
      key={`${item.fromDate}-${item.toDate}-${index}`}
      className="relative pl-8 sm:pl-32 py-6 group"
    >
      <div className="font-semibold text-xl uppercase text-white mb-1 sm:mb-0">
        {item.designation}
      </div>
      <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-indigo-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
        <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-amber-200 ">
          {`${item.fromDate} - ${item.toDate}`}
        </time>
        <div className="text-lg text-white">{item.companyName}</div>
      </div>
      <div className="text-slate-300">
        {/* <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide"> */}
        {item.description?.map((desc, idx) => (
          <p key={`${item.fromDate}-${item.toDate}-desc-${idx}`}>{desc}</p>
        ))}
        {/* </Spoiler> */}
        {/* <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide"> */}
        <div className="mt-3">
          {item.skills?.map((skill, idx) => (
            <span
              key={`${item.fromDate}-${item.toDate}-skills-${idx}`}
              className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-2 mb-2"
            >
              {skill}
            </span>
          ))}
        </div>
        {/* </Spoiler> */}
      </div>
    </div>
  );
}

function Work() {
  const { t } = useTranslation("resume");
  return (
    <PageTransition>
      {/* <Title>{t("Experience")}</Title> */}
      <div className="row">
        <div className="overscroll-contain timeline timeline-second-style clearfix">
          {t("experience", { ns: "resume", returnObjects: true }).map(
            (el, index) => ExperienceItem(el, index)
          )}
        </div>
      </div>
    </PageTransition>
  );
}
export default Work;
