import { motion } from "framer-motion";

const animation = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      // duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
  exit: { y: -20, opacity: 0 },
};

type Props = JSX.Element | JSX.Element[] | string | string[];

const Title = ({ children }: { children: Props }) => {
  return (
    <motion.h1
      variants={animation}
      initial="initial"
      animate="animate"
      exit="exit"
      className="capitalize"
    >
      {children}
    </motion.h1>
  );
};

export default Title;
